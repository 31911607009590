import React from 'react';

import {
  Container,
  AboutLeftContainer,
  SobreLeftContainer,
  AboutRightContainer,
} from './styles';

import TruckIcon from '@/images/paginas/unidade/truck.svg';
import SobreIcon from '@/images/paginas/unidade/sobre-icon.svg';

interface AboutSectionProps {
  data: {
    sobre: string;
    cidadesAtendidas: string[];
  };
}

const AboutSection: React.FC<AboutSectionProps> = ({
  data: { sobre, cidadesAtendidas },
}) => {
  return (
    <Container>
      <AboutLeftContainer>
        <img src={SobreIcon} alt="Ícone sobre" />

        <div>
          <h2>Sobre</h2>

          <SobreLeftContainer
            key="test"
            id="___gatsby"
            dangerouslySetInnerHTML={{ __html: sobre }}
          />
        </div>
      </AboutLeftContainer>

      <AboutRightContainer>
        <img src={TruckIcon} alt="Ícone de caminhão" />

        <div>
          <h2>Cidades atendidas</h2>

          <div>
            {cidadesAtendidas.map((cidade) => (
              <p key={cidade}>{cidade}</p>
            ))}
          </div>
        </div>
      </AboutRightContainer>
    </Container>
  );
};

export default AboutSection;
