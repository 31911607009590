import React from 'react';
import LocalizacaoIcon from '@/images/paginas/unidade/localizacao.svg';
import TelefoneIcon from '@/images/paginas/unidade/telefone.svg';

import {
  Container,
  MainInfoLeftContainer,
  MainInfoLeftLocalizacaoContainer,
  MainInfoRightContainer,
  NumbersContainer,
} from './styles';
import { CMS_URL } from '@/config';
import { removeAllNonNumberCharacters } from '@/utils/removeAllNonNumberCharacters';
import { Link } from 'gatsby';

interface AboutSectionProps {
  data: {
    nome: string;
    localizacao: string;
    endereco: string;
    unidadeImage: string;
    numero1: string;
    numero2: string;
  };
}

const MainInfoSection: React.FC<AboutSectionProps> = ({
  data: { nome, localizacao, endereco, unidadeImage, numero1, numero2 },
}) => {
  return (
    <Container>
      <MainInfoLeftContainer>
        <h1>{nome}</h1>

        <MainInfoLeftLocalizacaoContainer>
          <img src={LocalizacaoIcon} alt="ícone de localização" />

          <p>{localizacao}</p>
        </MainInfoLeftLocalizacaoContainer>

        <p>{endereco}</p>

        {numero1 && (
          <NumbersContainer>
            <img src={TelefoneIcon} alt="ícone de telefone" />

            <p>
              <a href={`tel:${removeAllNonNumberCharacters(numero1)}`}>
                {numero1}
              </a>
              {numero2 && (
                <>
                  {` / `}
                  <a href={`tel:${removeAllNonNumberCharacters(numero1)}`}>
                    {numero2}
                  </a>
                </>
              )}
            </p>
          </NumbersContainer>
        )}

        {/* <a
          href="https://cliente.generoso.com.br/coleta/coletar"
          target="_blank"
          rel="noreferrer"
          className="agendarColetaButton"
        >
          Agendar coleta{' '}
          <img src={IconNewPageWhite} alt="ícone de nova página" />
        </a> */}
        <Link className="queroSerClienteButton" to="/contato">
          Quero ser cliente
        </Link>
      </MainInfoLeftContainer>

      <MainInfoRightContainer>
        <img src={`${CMS_URL}/assets/${unidadeImage}`} alt="Ícone da unidade" />
      </MainInfoRightContainer>
    </Container>
  );
};

export default MainInfoSection;
