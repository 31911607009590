import React, { useMemo } from 'react';

import SEO from '@/components/SEO';
import Layout from '@/components/Layout';
import MainInfoSection from './MainInfoSection';
import AboutSection from './AboutSection';

import {
  TopContainer,
  BottomContainer,
  Wrapper,
  MapContainer,
  BreadCrumbsWithStyle,
  Separator,
} from './styles';

interface UnidadePageProps {
  pageContext: {
    id: string;
    imagem: string;
    slug: string;
    nome: string;
    mapa: string;
    localizacao: string;
    endereco: string;
    cidadesAtendidas: string[];
    sobre: string;
    number1: string;
    number2: string;
    seo: {
      title: string;
      description: string;
      keywords: string[];
      image: {
        id: string;
      };
    };
  };
}

const UnidadePage: React.FC<UnidadePageProps> = ({ pageContext }) => {
  const pageBreadCrumbs = useMemo(
    () => [
      { name: 'Página inicial', relativeURL: '/' },
      { name: 'Unidades', relativeURL: '/unidades' },
      { name: pageContext.nome },
    ],
    [pageContext.nome]
  );

  let sortedCidadesAtendidas = [''];

  if (pageContext.cidadesAtendidas) {
    sortedCidadesAtendidas = pageContext.cidadesAtendidas.sort((a, b) => {
      if (a > b) {
        return 1;
      }

      if (a < b) {
        return -1;
      }

      return 0;
    });
  }

  return (
    <>
      <SEO
        pageTitle={pageContext.nome}
        title={pageContext.seo?.title}
        description={pageContext.seo?.description}
        image={pageContext.imagem}
      />

      <Layout>
        <Wrapper>
          <MapContainer
            key={pageContext.id + 'mapa'}
            id="___gatsby"
            dangerouslySetInnerHTML={{ __html: pageContext.mapa }}
          />

          <TopContainer>
            <BreadCrumbsWithStyle pageBreadCrumbs={pageBreadCrumbs} />

            <MainInfoSection
              data={{
                nome: pageContext.nome,
                localizacao: pageContext.localizacao,
                endereco: pageContext.endereco,
                unidadeImage: pageContext.imagem,
                numero1: pageContext.number1,
                numero2: pageContext.number2,
              }}
            />
          </TopContainer>

          <Separator />

          <BottomContainer>
            <AboutSection
              data={{
                sobre: pageContext.sobre,
                cidadesAtendidas: sortedCidadesAtendidas,
              }}
            />
          </BottomContainer>
        </Wrapper>
      </Layout>
    </>
  );
};

export default UnidadePage;
