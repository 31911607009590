import styled from 'styled-components';

import BreadCrumbs from '@/components/BreadCrumbs';

import { colors } from '@/styles/GlobalStyle';

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  max-width: 912px;

  @media (max-width: 936px) {
    padding: 0 29px;
  }

  @media (max-width: 499px) {
    padding: 0 16px;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  max-width: 912px;

  overflow: hidden;

  iframe {
    width: 100%;
  }
`;

export const TopContainer = styled(Container)``;

export const BreadCrumbsWithStyle = styled(BreadCrumbs)`
  margin-top: 32px;

  @media (max-width: 780px) {
    display: none;
  }
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  max-width: 952px;

  background-color: ${colors.gray200};

  margin-top: 56px;
  margin-bottom: 24px;

  @media (max-width: 780px) {
    display: none;
  }
`;

export const BottomContainer = styled(Container)``;
