import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const Container = styled.section`
  display: flex;

  margin-bottom: 97px;

  h2 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }

  @media (max-width: 780px) {
    border-top: none;

    flex-direction: column;

    margin-top: 48px;
    margin-bottom: 40px;
  }
`;

export const AboutLeftContainer = styled.div`
  display: flex;
  align-items: flex-start;

  img {
    width: 20px;
    height: 20px;
  }

  > div {
    display: flex;
    flex-direction: column;

    margin-left: 18.21px;

    div {
      margin-top: 8px;

      width: 100%;
      max-width: 428px;

      p,
      blockquote {
        font-size: 16px;
        line-height: 20px;
        font-family: Poppins;
        font-weight: normal;
        color: ${colors.gray400};
      }

      p + p {
        margin-top: 24px;
      }
    }
  }
`;

export const SobreLeftContainer = styled.div``;

export const AboutRightContainer = styled.div`
  display: flex;
  align-items: flex-start;

  margin-left: 121px;

  img {
    width: 20px;
    height: 20px;
  }

  > div {
    display: flex;
    flex-direction: column;

    margin-left: 18.21px;

    div {
      margin-top: 8px;

      width: 100%;
      max-width: 428px;

      p {
        font-size: 16px;
        line-height: 20px;
        font-family: Poppins;
        font-weight: normal;
        color: ${colors.gray400};
      }

      p + p {
        margin-top: 4px;
      }
    }
  }

  @media (max-width: 780px) {
    margin-top: 48px;
    margin-left: 0;
  }
`;
