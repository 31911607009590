import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const Container = styled.section`
  display: flex;
  justify-content: space-between;

  margin-top: 40px;

  max-height: 295px;

  @media (max-width: 780px) {
    flex-direction: column;

    margin-top: 25px;
    max-height: fit-content;
  }
`;

export const MainInfoLeftContainer = styled.div`
  width: 100%;
  max-width: 292px;

  h1 {
    margin: 0;

    font-size: 24px;
    font-weight: bold;
    font-family: Poppins;
  }

  > p {
    margin-top: 16px;

    font-size: 16px;
    color: ${colors.gray400};
    line-height: 20px;
  }

  .queroSerClienteButton {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 35px;
    padding: 8px 24px;
    height: 40px;

    width: 192px;

    border: none;
    border-radius: 8px;

    background-color: ${colors.blue400};
    color: ${colors.white};
    font-size: 16px;

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }
  }
`;

export const MainInfoLeftLocalizacaoContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 11px;

  p {
    font-size: 16px;
    font-weight: normal;
    color: ${colors.gray600};

    margin-left: 12px;
  }
`;

export const NumbersContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 11px;

  p {
    font-size: 16px;
    font-weight: normal;

    margin-left: 12px;
  }

  a {
    :hover {
      text-decoration: underline;
    }
  }
`;

export const MainInfoRightContainer = styled.div`
  img {
    border-radius: 6px;
    max-height: 295px;
    max-width: 450px;
  }

  @media (max-width: 780px) {
    margin-top: 24px;
  }

  @media (max-width: 515px) {
    img {
      max-height: 200px;
    }
  }

  @media (max-width: 360px) {
    img {
      max-height: 150px;
    }
  }
`;
